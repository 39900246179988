import React from 'react';
import { Box, Text, Tooltip } from '@chakra-ui/core';
import { Link } from "react-router-dom";
import { format as timeAgo } from "timeago.js";

import Error from "./error";
import { useSpaceX } from '../utils/use-space-x';
import { formatDateTime } from '../utils/format-date';

export default function NextLaunchBanner () {
  const { data: nextLaunch, error } = useSpaceX(
    "/launches/next",
    null,
    null,
    'GET'
  );

  if (error) return <Error />;
  if (!nextLaunch) {
    return <></>;
  }

  return (
    <Box
      as={Link}
      to={`/launches/${nextLaunch.id}`}
      bg="blue.800"
      color="white"
      w="100%"
      p={2}
      d="flex"
      justifyContent="center"
    >
      <Tooltip
          label={formatDateTime(nextLaunch.date_local)}
      >
        <Text>
            <span role="img" aria-label="Rocket emoji">🚀</span>
            {` Next Launch ${timeAgo(nextLaunch.date_utc)}: ${nextLaunch.name}`}
        </Text>
      </Tooltip>
    </Box>
  );
}