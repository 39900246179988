import React, { useContext } from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Heading,
  Stack,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/core";
import { Star } from "react-feather";

import Error from "./error";
import { LaunchItem } from "./launches"
import { LaunchPadItem } from "./launch-pads"
import { FavoritesContext } from "../utils/favorites"
import { useLaunch, useLaunchPad } from "../utils/use-space-x"

export default function FavoritesDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ favorites ] = useContext(FavoritesContext);

  return (
    <>
      <Button
        onClick={onOpen}
        aria-label="See favorites"
        variant="outline"
      >
        <Box as={Star} width="1em" />
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        scrollBehavior="inside"
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            Favorites
          </DrawerHeader>

          <DrawerBody>
            <Stack spacing={8}>
            {Object.entries(favorites).map(([type, items]) => {
              if(items.length) {
                return (
                  <Stack key={type} spacing={4}>
                    <Heading as="h5" size="sm">{type}</Heading>
                    {items.map(item => 
                      <Flex key={item}>
                        <FavoriteItem type={type} item={item} />
                      </Flex>
                    )}
                  </Stack>
                );
              }
              return '';
            })}
            {Object.keys(favorites).length === 0 &&
              <Text>You can add favorites by clicking on the star icon on launches or launchpads!</Text>
            }
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

function FavoriteItem(props) {
  let Component;
  let componentProp;
  let hook;

  switch (props.type) {
    case "Launches":
      hook = useLaunch;
      componentProp = 'launch';
      Component = LaunchItem;
      break;
    case "Launch Pads":
      hook = useLaunchPad;
      componentProp = 'launchPad';
      Component = LaunchPadItem;
      break;
    default:
      throw new Error("Invalid favorite type");
  }

  const { data, error } = hook(props.item);
  const doc = data?.docs[0]
  if (error) return <Error />;
  if (!doc) {
    return (
      <Flex justifyContent="center" alignItems="center" minHeight="20vh">
        <Spinner size="sm" />
      </Flex>
    );
  }

  return (
    <Component { ...{ [componentProp]: doc } } />
  );
}
