import React, { useContext } from "react";
import {
  Box,
  Button,
} from "@chakra-ui/core";
import { Star } from "react-feather";

import {
  isFavorite,
  FavoritesContext
} from "../utils/favorites";

export default function FavoritesToggle ({ type, id, variant="ghost" }) {
  const [ favorites, toggleFavorite ] = useContext(FavoritesContext);
  const isFav = isFavorite(favorites, type, id);

  return (
    <Button
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        toggleFavorite(type, id);
      }}
      aria-label="Toggle favorite"
      variant={variant}
    >
      <Box
        as={Star}
        stroke={isFav ? '#debe00' : null}
        fill={isFav ? '#ff0' : 'none'}
        width="1.5em"
      />
    </Button>
  );
}
