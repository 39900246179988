import React from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { format as timeAgo } from "timeago.js";
import { Watch, MapPin, Navigation, Layers } from "react-feather";
import {
  Flex,
  Heading,
  Badge,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  SimpleGrid,
  Box,
  Text,
  Spinner,
  Image,
  Link,
  Stack,
  AspectRatioBox,
  StatGroup,
  Tooltip,
} from "@chakra-ui/core";

import { useLaunch } from "../utils/use-space-x";
import { formatDateTime } from "../utils/format-date";
import Error from "./error";
import Breadcrumbs from "./breadcrumbs";
import FavoritesToggle from "./favorites-toggle";

export default function Launch() {
  let { launchId } = useParams();
  const { data, error } = useLaunch(launchId);
  const launch = data?.docs[0];

  if (error) return <Error />;
  if (!launch) {
    return (
      <Flex justifyContent="center" alignItems="center" minHeight="50vh">
        <Spinner size="lg" />
      </Flex>
    );
  }

  return (
    <div>
      <Breadcrumbs
        items={[
          { label: "Home", to: "/" },
          { label: "Launches", to: ".." },
          { label: `#${launch.flight_number}` },
        ]}
      />
      <Header launch={launch} />
      <Box m={[3, 6]}>
        <TimeAndLocation launch={launch} />
        <RocketInfo launch={launch} />
        <Text color="gray.700" fontSize={["md", null, "lg"]} my="8">
          {launch.details}
        </Text>
        <Video launch={launch} />
        <Gallery images={launch.links.flickr.original} />
      </Box>
    </div>
  );
}

function Header({ launch }) {
  return (
    <Flex
      bgImage={`url(${
        launch.links.flickr.original[0]?.replace("_o.jpg", "_z.jpg") ??
        launch.links.patch.large
      })`}
      bgPos="center"
      bgSize="cover"
      bgRepeat="no-repeat"
      minHeight="30vh"
      position="relative"
      p={[2, 6]}
      alignItems="flex-end"
      justifyContent="space-between"
    >
      <Stack isInline position="absolute" top={5} right={5}>
        <Image
          src={launch.links.patch.small}
          height={["85px", "125px"]}
          objectFit="contain"
          objectPosition="bottom"
        />
        <FavoritesToggle type="Launches" id={launch.id} variant="solid" />
      </Stack>
      <Heading
        color="white"
        display="inline"
        backgroundColor="#718096b8"
        fontSize={["lg", "5xl"]}
        px="4"
        py="2"
        borderRadius="lg"
      >
        {launch.name}
      </Heading>
      <Stack isInline spacing="3">
        <Badge variantColor="purple" fontSize={["xs", "md"]}>
          #{launch.flight_number}
        </Badge>
        {launch.upcoming ? (
            <Badge variantColor="blue" fontSize={["xs", "md"]}>
              Upcoming
            </Badge>
          ) :
          launch.success ? (
            <Badge variantColor="green" fontSize={["xs", "md"]}>
              Successful
            </Badge>
          ) : (
            <Badge variantColor="red" fontSize={["xs", "md"]}>
              Failed
            </Badge>
          )
        }
      </Stack>
    </Flex>
  );
}

function TimeAndLocation({ launch }) {
  return (
    <SimpleGrid columns={[1, 1, 2]} borderWidth="1px" p="4" borderRadius="md">
      <Stat>
        <StatLabel display="flex">
          <Box as={Watch} width="1em" />{" "}
          <Box ml="2" as="span">
            Launch Date
          </Box>
        </StatLabel>
        <StatNumber fontSize={["md", "xl"]}>
          <Tooltip
            label={formatDateTime(launch.date_local)}
          >
            {launch.date_local}
          </Tooltip>
        </StatNumber>
        <StatHelpText>{timeAgo(launch.date_utc)}</StatHelpText>
      </Stat>
      <Stat>
        <StatLabel display="flex">
          <Box as={MapPin} width="1em" />{" "}
          <Box ml="2" as="span">
            Launch Site
          </Box>
        </StatLabel>
        <StatNumber fontSize={["md", "xl"]}>
          <Link
            as={RouterLink}
            to={`/launch-pads/${launch.launchpad.id}`}
          >
            {launch.launchpad.full_name}
          </Link>
        </StatNumber>
        <StatHelpText>{launch.launchpad.name}</StatHelpText>
      </Stat>
    </SimpleGrid>
  );
}

function RocketInfo({ launch }) {
  const cores = launch.cores;

  return (
    <SimpleGrid
      columns={[1, 1, 2]}
      borderWidth="1px"
      mt="4"
      p="4"
      borderRadius="md"
    >
      <Stat>
        <StatLabel display="flex">
          <Box as={Navigation} width="1em" />{" "}
          <Box ml="2" as="span">
            Rocket
          </Box>
        </StatLabel>
        <StatNumber fontSize={["md", "xl"]}>
          {launch.rocket.name}
        </StatNumber>
        <StatHelpText>{launch.rocket.type}</StatHelpText>
      </Stat>
      <StatGroup>
        <Stat>
          <StatLabel display="flex">
            <Box as={Layers} width="1em" />{" "}
            <Box ml="2" as="span">
              First Stage
            </Box>
          </StatLabel>
          <StatNumber fontSize={["md", "xl"]}>
            {launch.upcoming 
              ? "Upcoming"
              : cores.every((core) => core.landing_success)
                ? cores.length === 1
                  ? "Recovered"
                  : "All recovered"
                : "Lost"}
          </StatNumber>
        </Stat>
        <Stat>
          <StatLabel display="flex">
            <Box as={Layers} width="1em" />{" "}
            <Box ml="2" as="span">
              Second Stage
            </Box>
          </StatLabel>
          <StatNumber fontSize={["md", "xl"]}>
            Payload:{" "}
            {launch.payloads
              .map((payload) => payload.type)
              .join(", ")}
          </StatNumber>
        </Stat>
      </StatGroup>
    </SimpleGrid>
  );
}

function Video({ launch }) {
  if(!launch.links.youtube_id) { return null; }
  return (
    <AspectRatioBox maxH="400px" ratio={1.7}>
      <Box
        as="iframe"
        title={launch.mission_name}
        src={`https://www.youtube.com/embed/${launch.links.youtube_id}`}
        allowFullScreen
      />
    </AspectRatioBox>
  );
}

function Gallery({ images }) {
  if(images.length === 0) { return null; }
  return (
    <SimpleGrid my="6" minChildWidth="350px" spacing="4">
      {images.map((image) => (
        <a href={image} key={image}>
          <Image src={image.replace("_o.jpg", "_z.jpg")} />
        </a>
      ))}
    </SimpleGrid>
  );
}
