import useSWR, { useSWRInfinite } from "swr";

const fetcher = async (url, query, options, method='POST') => {
  let fetchOptions;
  if (method === 'POST') {
    fetchOptions = {
      method,
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        query,
        options
      })
    }
  }

  const response = await fetch(url, fetchOptions);
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return await response.json();
};

function getSpaceXUrl(path) {
  return `${process.env.REACT_APP_SPACEX_API_URL}${path}`;
}

export function useSpaceX(path, query, options, method) {
  const endpointUrl = getSpaceXUrl(path);
  const key = path ? [
    endpointUrl,
    JSON.stringify(query),
    JSON.stringify(options),
  ] : null;
  const fn = (url) => fetcher(
    url,
    query,
    options,
    method
  );
  return useSWR(key, fn);
}

export function useSpaceXPaginated(path, query, options) {
  const key = (pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData.hasNextPage) {
      return null;
    }
    const offset = options.limit * pageIndex;
    return [
      getSpaceXUrl(path),
      offset,
      JSON.stringify(query),
      JSON.stringify(options)
    ];
  };

  const fn = (url, offset) => fetcher(
    url,
    query,
    {
      ...options,
      offset
    }
  );
  return useSWRInfinite(key, fn);
}

export function useLaunch(id) {
  return useSpaceX(
    `/launches/query`,
    {
      _id: id
    },
    {
      populate: [
        "rocket",
        "payloads",
        "launchpad"
      ]
    }
  );
}

export function useLaunchPad(id) {
  return useSpaceX(
    "/launchpads/query",
    { _id: id },
    {
      populate: [
        {
          path: "launches",
          populate: [
            "launchpad",
            "rocket"
          ]
        },
        "rockets"
      ]
    }
  );
}
