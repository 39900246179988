import React, { useState, createContext } from 'react';

export const FavoritesContext = createContext();

export function FavoritesContextProvider ({ children }) {
  const [favorites, setFavorites] = useState(getFavorites());

  const toggleFavorite = (type, id) => {
    if(favorites[type]?.includes(id)) {
      favorites[type] = favorites[type].filter(fav => fav !== id);
      if(favorites[type].length === 0) {
        delete favorites[type];
      }
    } else {
      favorites[type] = favorites[type] || [];
      favorites[type].push(id);
    }
    setFavorites({ ...favorites });
    window.localStorage.setItem('favorites', JSON.stringify(favorites));
  };

  return (
    <FavoritesContext.Provider value={[favorites, toggleFavorite]}>
      {children}
    </FavoritesContext.Provider>
  );
}

export function isFavorite(favorites, type, id) {
  return favorites[type]?.includes(id);
}

function getFavorites() {
  return JSON.parse(window.localStorage.getItem('favorites')) || {};
}
